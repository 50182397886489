import * as React from "react"

import { Page, PageHeader, PageSection, SEO } from "src/components"

export default function AboutUsPage() {
    return (
        <Page>
            <SEO title="AboutUs"/>

            <PageHeader header="About Us" />

            <PageSection>
                <a id="introduction"><h1>Introduction</h1></a>
                <p>Hello</p>
            </PageSection>
        </Page>
    )
}
